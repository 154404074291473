<template>
  <img src="../assets/HackNJIT2024/hatguy/hatguy0.png" />
</template>

<script>
export default {};
</script>

<style scoped>
img {
  z-index: -2;
  width: 100%;
}
@media (max-width: 750px) {
  img {
    width: unset;
    max-height: 42.5vh;
  }
}
</style>